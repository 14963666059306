<template>
  <Toast />

  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Configuraciones</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Mi Empresa</span>
    </li>
  </ul>
  <div class="grid">
    <div class="col-12 md:col-6">
      <div class="card">
        <div class="p-fluid formgrid grid">
          <h5><strong>DATOS DE FACTURACI&Oacute;N</strong></h5>
          <div class="field col-12 md:col-12">
            <Button
              class="p-button p-button-lg p-button-success"
              label="SINCRONIZAR CON SIN"
              icon="pi pi-refresh"
              @click="sincronizarSIN"
              v-tooltip.top="'Sincronizar con SIN'"
              :loading="loading"
            />
          </div>
          <div class="field col-12 md:col-12">
            <label for="sucursal"><strong>SUCURSAL: </strong></label>
            <Dropdown
              id="sucursal"
              v-model="sucursalSelect"
              :options="sucursales"
              optionLabel="nombre"
            />
          </div>

          <div class="field col-12 md:col-12">
            <label for="nit"><strong>NIT:</strong></label>
            <InputText id="nit" type="text" v-model="empresa.nit" disabled />
          </div>
          <div class="field col-12 md:col-12">
            <label for="razon_social"
              ><strong>RAZ&Oacute;N SOCIAL EMISOR:</strong></label
            >
            <InputText
              id="razon_social"
              type="text"
              v-model="empresa.razon_social"
              placeholder="Raz&oacute;n Social"
              disabled
            />
          </div>
          <div class="field col-12 md:col-12">
            <label for="direccion"
              ><strong>INFORMACI&Oacute;N SUCURSAL | NOMBRE</strong></label
            >
            <InputText
              id="direccion"
              type="text"
              v-model="empresa.direccion"
              placeholder="Direcci&oacute;n"
              disabled
            />
          </div>
          <div class="field col-12 md:col-12">
            <label for="actividad_economica"
              ><strong>ACTIVIDAD ECONOMICA:</strong></label
            >
            <div class="p-inputgroup">
              <Dropdown
                id="actividad_economica"
                v-model="empresa.codigoActividad"
                :options="actividades"
                optionLabel="descripcion"
                optionValue="codigo"
                @change="validarFrm"
              />
            </div>
          </div>
          <div class="field col-12 md:col-12">
            <label for="codigoDocumentoSector"
              ><strong>C&Oacute;DIGO DOCUMENTO SECTOR:</strong></label
            >
            <div class="p-inputgroup">
              <Dropdown
                id="codigoDocumentoSector"
                v-model="empresa.codigoDocumentoSector"
                :options="codigos"
                optionLabel="descripcion"
                optionValue="codigoDocumentoSector"
                @change="updateCodigoDocumentoSector"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="card p-fluid">
        <h5><strong>Logo</strong></h5>
        <div class="field">
          <img
            v-if="empresa.logo"
            class="product-image"
            :src="'data:image/png;base64, ' + empresa.logo"
            alt="IMAGEN LOGO"
            width="150"
            height="90"
          />
          <div v-else>Loading...</div>
          <FileUpload
            accept="image/*"
            id="file"
            name="file[]"
            type="file"
            enctype="multipart/form-data"
            :customUpload="true"
            @uploader="updatedImg"
            uploadLabel="Cargar Logo"
            mode="basic"
            label="Cargar Logo"
            v-tooltip.top="'Cargar Logo'"
            :chooseLabel="empresa.logo ? 'CAMBIAR LOGO' : 'CARGAR LOGO'"
            class="p-button-outlined mr-2 p-button"
            :auto="true"
          />
        </div>
      </div>
      <div class="card p-fluid">
        <h5><strong>FACTURACIÓN ELECTRÓNICA</strong></h5>
        <div class="field">
          <label for="api_key"><strong>API KEY:</strong></label>
          <Textarea
            id="api_key"
            type="text"
            v-model="empresa.api_key"
            rows="5"
            cols="30"
          />
        </div>
        <div class="field">
          <label for="token_sin"><strong>TOKEN SIN:</strong></label>
          <Textarea
            id="token_sin"
            type="text"
            v-model="empresa.token_sin"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmpresaService from "@/service/EmpresaService";
import SucursalService from "@/service/SucursalService";

export default {
  data() {
    return {
      empresa: {},
      actividades: [],
      //hago que se seleccione la primera actividad
      actividadSelected: null,
      loading: false,
      codigos: [],
      sucursalSelect: null,
      sucursales: [],
    };
  },
  empresaService: null,
  sucursalService: null,

  mounted() {
    this.cargarActividades();
    this.cargarCodigoDocumentoSector();
    this.cargarSucursales();
  },
  created() {
    this.empresaService = new EmpresaService();
    this.empresaService.getFirstEmpresa().then((response) => {
      this.empresa = { ...response.data };
    });
    this.sucursalService = new SucursalService();
  },
  methods: {
    cargarSucursales() {
      this.sucursalService.getSucursalesAll().then((response) => {
        this.sucursales = response;
        this.sucursalSelect = this.sucursales[0] ?? null;
      });
    },
    cargarCodigoDocumentoSector() {
      this.empresaService.getCodigoDocumentoSector().then((response) => {
        this.codigos = response.codigoDocumentoSector || [];
      });
    },
    updateCodigoDocumentoSector() {
      let datos = {
        codigoDocumentoSector: this.empresa.codigoDocumentoSector,
        sucursal_id: this.sucursalSelect.id,
      };
      this.empresaService
        .actualizarCodigoDocumentoSector(datos)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Actualizado",
            detail: response.message,
            life: 3000,
          });
        });
    },
    cargarActividades() {
      this.empresaService.getActividades().then((response) => {
        this.actividades = response.actividadEconomicas || [];
      });
    },
    cargarDatos() {
      this.$router.go();
    },
    sincronizarSIN() {
      this.loading = true;
      //le paso la sucursal seleccionada
      let datos = {
        ...this.empresa,
        sucursal_id: this.sucursalSelect.id,
      };
      this.empresaService.sincronizarSIN(datos).then((data) => {
        if (data.status == 400) {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.message,
            life: 3000,
          });
        } else {
          this.loading = false;
          this.$toast.add({
            severity: "success",
            summary: "Sincronizado",
            detail: data.message,
            life: 3000,
          });
          this.cargarDatos();
        }
      });
    },
    validarFrm() {
      //actualizar datos
      //paso como variale de mi empresa la actividad seleccionada
      let datos = {
        ...this.empresa,
        codigoActividad: this.empresa.codigoActividad,
        sucursal_id: this.sucursalSelect.id,
      };

      this.empresaService
        .updateEmpresa(this.empresa.id, datos)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Actualizado",
            detail: response.message,
            life: 3000,
          });
        });
    },
    updatedImg(event) {
      let empresa_id = this.empresa.id;
      let archivo = event.files[0];
      let formData = new FormData();
      formData.append("logo", archivo);
      formData.append("id", empresa_id);
      formData.append("nombre", this.empresa.nombre);
      formData.append("direccion", this.empresa.direccion);
      formData.append("razon_social", this.empresa.razon_social);
      formData.append("nit", this.empresa.nit);

      this.empresaService
        .updateEmpresaLogo(empresa_id, formData)
        .then((response) => {
          if (response.res == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.message,
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Actualizado",
              detail: response.message,
              life: 3000,
            });
            this.cargarDatos();
          }
        });
    },
  },
};
</script>
